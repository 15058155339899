import React from 'react'
import kodaris from './images/kodaris.png'
import './App.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={kodaris} className="App-logo" alt="logo" />
        <h3>Kodawaris</h3>
      </header>
    </div>
  )
}

export default App
